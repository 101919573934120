import Link from 'next/link'
import { useEffect } from 'react'

import { PageSectionViewModel } from '@shared/models/page'

import styles from '../../styles/Provides.module.css'

type ProvidesProps = {
  data: PageSectionViewModel | undefined;
  lang: string;
}

function Provides({ data, lang }: ProvidesProps) {
  useEffect(() =>
    {     
      const video = document.body.querySelector('video') as HTMLVideoElement;
      video.muted = true;
      const playPromise = video.play();

      if (playPromise !== undefined) {
        playPromise.then(_ => {
          // Automatic playback started!
          // Show playing UI.
        })
        .catch(error => {
          // Auto-play was prevented
          // Show paused UI.
        });
      }
    }
  );

  const learnMore = lang === 'en' ? 'Learn more' : 'Μάθετε Περισσότερα';

  return (
    <section className={styles.video_container + ' relative py-32'}>
      <div className={styles.videoDiv + " absolute top-0 left-0 h-1/2 w-full object-cover"}> 
        <video className={styles.video1} preload="true" autoPlay={true} muted={true} playsInline={true} loop={true} controls={false}>
        <source src={data?.content.backgroundVideo} type="video/mp4"></source>
        </video>
      </div>
      <div className={styles.video_gradient + ' absolute top-0 right-0 h-full w-full -z-1'}></div>
      <div className="relative container px-4 mx-auto">
        <div className={styles.video_text + ' max-w-md sm:max-w-xl mx-auto md:mr-0'}>
          <h1 className="font-heading text-3xl sm:text-4xl text-white mb-6 drop-shadow-lg shadow-black">{data?.content.title}</h1>
          <p className="text-lg leading-8 text-white mb-10 text-justify">{data?.content.description}</p>
          <div className="sm:flex items-center"><Link className="inline-block w-full sm:w-auto py-4 px-6 text-center font-heading font-medium text-base text-white hover:text-green-300 border border-white hover:border-green-300 rounded-sm transition duration-150" href="/services" locale={lang} aria-label="Services Page">{learnMore}</Link></div>
        </div>
      </div>
    </section>
  )

}

export default Provides;