import Image from 'next/image'
import Link from 'next/link'

import { PageSectionViewModel, SectionSolutionViewModel } from '@shared/models/page'

type SolutionsProps = {
  data: PageSectionViewModel | undefined;
  lang: string;
}

function Solutions({ data, lang }: SolutionsProps) {
  const solutions: SectionSolutionViewModel[] = data?.content.solutions.sort((a,b) => (a.menuOrder > b.menuOrder) ? 1 : ((b.menuOrder > a.menuOrder) ? -1 : 0)) || [];
  
  const learnMore = lang === 'en' ? 'Learn more' : 'Μάθετε Περισσότερα';

  return (
    <section className="py-16 bg-gray-50">
      <div className="container px-4 mx-auto">
        <div className="flex flex-wrap items-center -mx-4 mb-12">
          <div className="w-full xl:w-3/4 px-4 mb-8 xl:mb-0">
            <h2 className="max-w-4xl font-heading text-3xl sm:text-4xl mb-6">{data?.content.title}</h2>
            <div className='text-xl text-green-700 mb-6'>{data?.content.subtitle_1}</div>
            <p className="text-lg text-justify">{data?.content.description}</p>
          </div>
          <div className="w-full xl:w-auto ml-auto px-4 xl:text-right"><Link className="inline-block w-full sm:w-auto py-4 px-6 text-center font-heading font-medium text-base border hover:text-green-500 border border-black hover:border-green-500 rounded-sm" href="/about" locale={lang} aria-label="About Page">{learnMore}</Link></div>
        </div>
        <div className="flex flex-wrap -mx-4 -mb-8">
          { solutions.map((solution) => (
            <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-8" key={solution.id}>
              <div className="max-w-md lg:max-w-none mx-auto p-6 bg-white service-card">
                <div className="flex items-center justify-center">
                  <div className="relative flex items-center justify-center w-16 h-16 mb-8 bg-green-50 rounded">
                    <Image src={solution.icon} alt={solution.title} width="32" height="32"></Image>
                  </div>
                </div>
                <div>
                  <h3 className="max-w-xxs font-heading font-medium text-lg text-center mb-4">{solution.title}</h3>
                  <p className="text-left leading-7">{solution.content}</p>
                </div>
              </div>
            </div>
            ))
          }
        </div>
      </div>
    </section>
  )
}

export default Solutions;
  