export type QueryParams = Partial<{[key: string]:  string | string[]}>

const QUERY_DELIM = ','

export function toString(queryParams: QueryParams): string {
  return Object.entries(queryParams)
    .map(([key, value]) => {
      if (value == null) {
        return key
      } else if(Array.isArray(value)) {
        return `${key}=${value.join(QUERY_DELIM)}`
      } else {
        return `${key}=${value}`
      }
    })
    .join('&')
}

