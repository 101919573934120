import { ReactElement } from 'react'

import ContactForm from '@shared/components/ContactForm'
import Discuss from '@shared/components/Discuss'
import Header from '@shared/components/Header'
import Layout from '@shared/components/Layout'
import Provides from '@shared/components/Provides'
import Services from '@shared/components/Services'
import Solutions from '@shared/components/Solutions'
import { HomePageModel, HomePageViewModel } from '@shared/models/home-page'
import { NextPageWithLayout } from '@shared/models/next-page'
import {
  PageAcfGeneralInformationViewModel, PageAcfSiteInfoModel, PageSectionViewModel
} from '@shared/models/page'
import { QueryParams } from '@shared/models/query-params'
import { fetchUrl, WP_HEADERS } from '@shared/utils/api.utils'
import { redirectToErrorPage } from '@shared/utils/global.utils'

import styles from '../styles/Home.module.css'

type HomeProps = {
  data: HomePageViewModel,
  locale: string,
  title: string,
  description: string
}

const Home: NextPageWithLayout<HomeProps> = ({ data, locale }) => {
  const page = data as HomePageViewModel

  const headerSection = page.sections?.find((section: PageSectionViewModel) => section.sectionSlug === `header-${data.language}`);
  const solutionsSection = page.sections?.find((section: PageSectionViewModel) => section.sectionSlug === `solutions-${data.language}`);
  const providesSection = page.sections?.find((section: PageSectionViewModel) => section.sectionSlug === `provides-${data.language}`);
  const servicesSection = page.sections?.find((section: PageSectionViewModel) => section.sectionSlug === `services-${data.language}`);

  const generalInfo = page.siteInfo.find((info: PageAcfSiteInfoModel) => info.post_name === `general_information-${data.language}`) as PageAcfSiteInfoModel | undefined;
  const generalInfoView = generalInfo ? new PageAcfGeneralInformationViewModel(generalInfo) : undefined;

  return (
    <main className={styles.main}>
      <Header data={headerSection}/>
      <Solutions data={solutionsSection} lang={locale}/>
      <Provides data={providesSection} lang={locale}/>
      <Services data={servicesSection}/>
      <Discuss siteInfo={generalInfoView} language={locale} />
      <ContactForm language={locale} />
    </main>
  )
}

export async function getServerSideProps(context: any) {
  const { locale } = context
  // Get Page Data from WP API
  const queryParams: QueryParams = {
    slug: `home-${locale}`
  }

  const result: Response = await fetch(fetchUrl('pages', queryParams), WP_HEADERS);
  const pagesRes: HomePageModel[]  = await result.json() as HomePageModel[];

  if (!pagesRes) {
    return redirectToErrorPage(locale);
  }

  const pages: HomePageViewModel[] = pagesRes.map((i: HomePageModel) => new HomePageViewModel(i, locale));

  const parsedData = JSON.parse(JSON.stringify(pages[0])) as HomePageViewModel;

  const headerSection = parsedData.sections?.find((section: PageSectionViewModel) => section.sectionSlug === `header-${locale}`);
  const title = headerSection?.content.title;
  const description = parsedData.metaDescription;
  
  return { 
    props: { data: parsedData, locale, title, description }
  }
}

Home.getLayout = function getLayout(page: ReactElement) {
  return (
    <Layout {...page.props}>
      {page}
    </Layout>
    );
};

export default Home;