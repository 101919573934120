import { useState } from 'react'

import { QueryParams } from '@shared/models/query-params'
import { fetchLocalApiUrl } from '@shared/utils/api.utils'
import { i18nTexts } from '@shared/utils/i18n.util'

type ContactProps = {
  language: string;
}

function ContactForm({ language }: ContactProps) {
  const texts = i18nTexts.find(item => item.id === language);

  const [firstNameInput, setFirstName] = useState("");
  const [lastNameInput, setLastName] = useState("");
  const [emailInput, setEmail] = useState("");
  const [phoneNumberInput, setPhoneNumber] = useState("");
  const [companyInput, setCompany] = useState("");
  const [messageInput, setMessage] = useState("");

  //   Form validation state
  const [errors, setErrors] = useState({});

  //   Setting button text on form submission
  const [buttonText, setButtonText] = useState(texts?.forms.sendLabel);

  // Setting success or failure messages states
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showFailureMessage, setShowFailureMessage] = useState(false);

  // Validation check method
  const handleValidation = () => {
    let tempErrors = {};
    let isValid = true;

    if (firstNameInput.length <= 0) {
      (tempErrors as any)["firstNameInput"] = true;
      isValid = false;
    }
    if (lastNameInput.length <= 0) {
      (tempErrors as any)["lastNameInput"] = true;
      isValid = false;
    }
    if (emailInput.length <= 0) {
      (tempErrors as any)["emailInput"] = true;
      isValid = false;
    }
    if (messageInput.length <= 0) {
      (tempErrors as any)["messageInput"] = true;
      isValid = false;
    }

    setErrors({ ...tempErrors });
    console.log("errors", errors);
    return isValid;
  };

  //   Handling form submit

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    let isValidForm = handleValidation();

    if (isValidForm) {
      setButtonText(texts?.forms.sendingLabel);
      console.log(isValidForm)

      const queryParams: QueryParams = {
        contact_email: emailInput,
        contact_name: firstNameInput + lastNameInput,
        contact_phoneNumber: phoneNumberInput,
        contact_company: companyInput,
        contact_message: messageInput,
      }

      const res = await fetch(fetchLocalApiUrl('contact', queryParams));

      const { error } = await res.json();
      if (error) {
        console.log(error);
        setShowSuccessMessage(false);
        setShowFailureMessage(true);
        setButtonText(texts?.forms.sendLabel || 'Send');
        return;
      }
      setShowSuccessMessage(true);
      setShowFailureMessage(false);
      setButtonText(texts?.forms.sendLabel || 'Send');
    }
  };
  
  return (
    <section className="py-16">
      <form onSubmit={handleSubmit}>
        <div className="container px-10 mx-auto">
          <h4 className="font-heading text-xl font-medium mb-4">{texts?.forms.contactFormLabel}</h4>
          <div id="contact" className="flex flex-wrap -mx-4">
            <div className="w-full lg:w-1/2 px-4 mb-16 lg:mb-0">
              <div className="max-w-xl">
                <div className="mb-4">
                  <label className="block text-sm leading-6 mb-2">{texts?.forms.firstNameFormLabel}*</label>
                  <input 
                    required 
                    className="block w-full p-4 font-heading text-gray-300 placeholder-gray-300 bg-gray-50 rounded outline-none" 
                    type="text" 
                    placeholder={`${texts?.forms.typeFormLabel}${texts?.forms.firstNameFormLabel}`}
                    value={firstNameInput}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}>
                  </input>
                </div>
                <div className="mb-4">
                  <label className="block text-sm leading-6 mb-2">{texts?.forms.lastNameFormLabel}*</label>
                  <input 
                    required
                    className="block w-full p-4 font-heading text-gray-300 placeholder-gray-300 bg-gray-50 rounded outline-none"
                    type="text"
                    placeholder={`${texts?.forms.typeFormLabel}${texts?.forms.lastNameFormLabel}`}
                    value={lastNameInput}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}>
                  </input>
                </div>
                <div className="mb-4">
                  <label className="block text-sm leading-6 mb-2">{texts?.forms.emailFormLabel}*</label>
                  <input
                    required
                    className="block w-full p-4 font-heading text-gray-300 placeholder-gray-300 bg-gray-50 rounded outline-none"
                    type="email"
                    placeholder={`${texts?.forms.typeFormLabel}${texts?.forms.emailFormLabel}`}
                    value={emailInput}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}>
                  </input>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/2 px-4">
              <div className="mb-4">
                <label className="block text-sm leading-6 mb-2">{texts?.forms.phoneFormLabel}</label>
                <input
                  minLength={10}
                  className="block w-full p-4 font-heading text-gray-300 placeholder-gray-300 bg-gray-50 rounded outline-none"
                  type="text"
                  placeholder={`${texts?.forms.typeFormLabel}${texts?.forms.phoneFormLabel}`}
                  value={phoneNumberInput}
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                  }}>
                </input>
              </div>
              <div className="mb-4">
                <label className="block text-sm leading-6 mb-2">{texts?.forms.companyFormLabel}</label>
                <input
                  className="block w-full p-4 font-heading text-gray-300 placeholder-gray-300 bg-gray-50 rounded outline-none"
                  type="text"
                  placeholder={`${texts?.forms.typeFormLabel}${texts?.forms.companyFormLabel}`}
                  value={companyInput}
                  onChange={(e) => {
                    setCompany(e.target.value);
                  }}>
                </input>
              </div>
              <div className="mb-6">
                <label className="block text-sm leading-6 mb-2">{texts?.forms.messageFormLabel}*</label>
                <textarea
                  required
                  className="block w-full h-40 p-4 font-heading text-gray-300 placeholder-gray-300 bg-gray-50 rounded outline-none resize-none"
                  placeholder={`${texts?.forms.typeFormLabel}${texts?.forms.messageFormLabel}`}
                  value={messageInput}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}>
                </textarea>
              </div>
              {!showSuccessMessage ? (
              <div className="text-right">
                <button className="inline-block w-full sm:w-auto py-4 px-6 mb-4 sm:mb-0 sm:mr-4 text-center font-heading font-medium text-base text-white bg-green-500 hover:bg-green-600 border border-green-500 hover:border-green-600 rounded-sm transition duration-200" type="submit" aria-label="send form">{buttonText}</button>
              </div>
              ) : <h4 className="font-heading text-lg font-medium mb-4">{texts?.forms.thankyouLabel}</h4> }          
            </div>
          </div>
        </div>
      </form>
    </section>
  )
}

export default ContactForm;