import { PageSectionViewModel, SectionSolutionViewModel } from '@shared/models/page'

type ServicesProps = {
  data: PageSectionViewModel | undefined;
}

function Services({ data }: ServicesProps) {
  const services: SectionSolutionViewModel[] = data?.content.services.sort((a,b) => (a.menuOrder > b.menuOrder) ? 1 : ((b.menuOrder > a.menuOrder) ? -1 : 0)) || [];

  return (
    <section className="py-16 bg-gray-50">
      <div className="container px-4 mx-auto">
        <div className="max-w-3xl px-4 mb-12">
          <h2 className="max-w-3xl font-heading text-3xl sm:text-4xl mb-6">{data?.content.title}</h2>
          <p className="text-lg leading-8 text-justify">{data?.content.description}</p>
        </div>
        <div className="flex flex-wrap -mx-4 -mb-12">
          <style jsx>{`
              .service_energy {
                background-image: url(${services[0]?.icon});
              }
              .service_onm {
                background-image: url(${services[1]?.icon});
              }
              .service_consulting {
                background-image: url(${services[2]?.icon});
              }
              .service_card {
                background-position: center;
                -o-background-size: cover;
                -moz-background-size: cover;
                -webkit-background-size: cover;
                background-size: cover;
                background-repeat: no-repeat;
              }
              .service_card_bg {
                background: -moz-linear-gradient(0deg,  rgba(82,82,82,0.45) 0%,rgba(10,10,10,0.5) 50%);
                background: -o-linear-gradient(top, rgba(82,82,82,0.5) 0%,rgba(10,10,10,0.5) 50%);
                background: -ms-linear-gradient(top, rgba(82,82,82,0.5) 0%,rgba(10,10,10,0.5) 50%);
                background: linear-gradient(0deg, rgba(82,82,82,0.5) 0%, rgba(10,10,10,0.5) 50%);
              }
            `}</style>
          <div className="w-full lg:w-1/3 px-4 mb-12">
            <div className="bg-white rounded max-w-sm mx-auto">
              <div className="service_energy service_card">
                <h3 className="h-40 w-full pt-8 px-8 rounded service_card_bg font-heading text-2xl text-white font-medium mb-4">{services[0]?.title}</h3>
              </div>
              <p className="p-8 max-w-xxs text-lg leading-7 text-left">{services[0]?.content}</p>
            </div>
          </div>
          <div className="w-full lg:w-1/3 px-4 mb-12">
            <div className="bg-white rounded max-w-sm mx-auto">
              <div className="service_onm service_card">
                <h3 className="h-40 w-full pt-8 px-8 rounded service_card_bg font-heading text-2xl text-white font-medium mb-4">{services[1]?.title}</h3>
              </div>
              <p className="p-8 max-w-xxs text-lg leading-7 text-left">{services[1]?.content}</p>
            </div>
          </div>
          <div className="w-full lg:w-1/3 px-4 mb-12">
            <div className="bg-white rounded max-w-sm mx-auto">
              <div className="service_consulting service_card">
                <h3 className="h-40 w-full pt-8 px-8 rounded service_card_bg font-heading text-2xl text-white font-medium mb-4">{services[2]?.title}</h3>
              </div>
              <p className="p-8 max-w-xxs text-lg leading-7 text-left">{services[2]?.content}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services;