
import Image from 'next/image'

import { PageSectionViewModel, SectionSolutionViewModel } from '@shared/models/page'

type HeaderProps = {
  data: PageSectionViewModel | undefined
}
  
function Header({ data }: HeaderProps) {

  const solutions: SectionSolutionViewModel[] = data?.content.solutions || [];
    
  return (
    !!data ? (
      <section className="header">
        <style jsx>{`
          .header {
            background-image: url(${data?.content.backgroundImage});
            background-attachment: fixed;
            background-position: center;
            -o-background-size: cover;
            -moz-background-size: cover;
            -webkit-background-size: cover;
            background-size: cover;
            background-repeat: no-repeat;
          }
          @media screen and (max-width: 640px) {
            .header {
              background-attachment: scroll;
              background-size:cover;
              background-position: auto;
            }
          }
          .header_bg {
            background: -moz-linear-gradient(0deg,  rgba(82,82,82,0.5) 0%,rgba(10,10,10,0.5) 70%);
            background: -o-linear-gradient(top, rgba(82,82,82,0.5) 0%,rgba(10,10,10,0.5) 70%);
            background: -ms-linear-gradient(top, rgba(82,82,82,0.5) 0%,rgba(10,10,10,0.5) 70%);
            background: linear-gradient(0deg, rgba(82,82,82,0.5) 0%, rgba(10,10,10,0.5) 70%);
          }
        `}</style>
        <div className="header_bg pt-24 sm:pt-34 bg-cover">
          <div className="container px-4 mx-auto">
            <div className="flex flex-wrap items-center -mx-4 pb-24 sm:pb-34">
              <div className="w-full lg:w-2/3 px-4 mb-6 lg:mb-0">
                <div className="max-w-md lg:max-w-none">
                  <div className="text-sm mb-2 font-semibold text-white uppercase drop-shadow-md shadow-black">
                    {/* <span>Technologies for <span className="text-green-500">GREEN</span> and Sustainable Environment</span> */}
                    <span className='text-green-500'>{data.content.subtitle_1}</span>
                  </div>
                  <h1 className="font-heading text-4xl lg:text-5xl text-white drop-shadow-lg shadow-black pt-5 pb-10">{data.content.title}</h1>
                </div>
              </div>
              <div className="w-full lg:w-1/2 xl:w-auto ml-auto px-4">
                <div className="max-w-lg">
                  <p className="text-2xl leading-8 text-white">{data.content.subtitle_2}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="relative py-12">
            <div className="absolute top-0 left-0 w-full h-full backdrop-blur backdrop-filter bg-gray-900 bg-opacity-50"></div>
            <div className="relative container px-4 mx-auto">
              <div className="flex flex-wrap -mx-4">
                { solutions.map((solution) => (
                  <div className="w-full md:w-1/3 px-4 mb-6 md:mb-0" key={solution.id}>
                    <div className="flex max-w-xxs sm:max-w-sm mx-auto">
                      <div className="flex-shrink-0 flex items-center justify-center w-14 lg:w-16 h-14 lg:h-16 mr-4 lg:mr-8 rounded-md text-green-500 bg-gray-200 bg-opacity-10">
                        <Image src={solution.icon} alt="end-to-end solutions" width="32" height="32"></Image>
                      </div>
                      <div>
                        <h4 className="text-lg font-heading text-white mb-2">{solution.title}</h4>
                        <p className="text-white text-left">{solution.content}</p>
                      </div>
                    </div>
                  </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    ) : (<></>)
  ) 
}

export default Header;
  