import { QueryParams, toString } from '@shared/models/query-params'

export const BASE_WP_URL = 'https://api.greenvalue.gr';
export const WP_URL = 'https://api.greenvalue.gr/wp-json/wp/v2';

// export const WP_HEADERS = new Headers([
//   ['Content-Type', 'application/json'],
//   ['Authorization', 'Basic Z3JlZW52YWx1ZV9mZTowZkhpZWNSTzRDYWQ1YiNQKSZLZGtDTjY=']
// ]);

export const WP_HEADERS = {
  'headers': {
    'Content-Type': 'application/json',
    'Authorization': 'Basic ' + process.env.AUTH_KEY as string,
    'Set-Cookie': 'cross-site-cookie=whatever; SameSite=None; Secure'
  }
}

export const fetchUrl = (pathParam: string, queryParams: QueryParams) => ([
  `${WP_URL}/${pathParam}`,
  '?',
  toString(queryParams)
].join('')
)

export const fetchLocalApiUrl = (pathParam: string, queryParams: QueryParams) => ([
  `/api/${pathParam}`,
  '?',
  toString(queryParams)
].join('')
)